import React from 'react';
import { PaletteMode } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    default: React.CSSProperties;
    disabled: React.CSSProperties;
    secondary: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    default?: React.CSSProperties;
    disabled?: React.CSSProperties;
    secondary?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    default: true;
    disabled: true;
    secondary: true;
  }
}

export const getDesignTokens = (mode: PaletteMode | undefined) => (
  {
    typography: {
      useNextVariants: true,
      fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
      fontSize: 14
    },
    components: {
      MuiGrid: {
        styleOverrides: {
          root: {
            fontSize: '14px'
          }
        }
      },
    },
    palette: {
      mode,
      ...(mode === 'light'
        ? {
          // palette values for light mode
          accent: '#F5F8FF',
        }
        : {
          // palette values for dark mode
          accent: '#477EFF',
        }),
    },
  }
);
