import React, { ReactNode, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import { Box, InputLabel, SxProps, Typography } from '@mui/material';
import { ODSlideOut, ODButton } from '@OptimalDynamics/core-ai-common-ui';
import EventNoteSharp from '@mui/icons-material/EventNoteSharp';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import instance from '../../../utils/axios_instance';
import { getLocalizedTimeRange, getTimeZoneAcronym, AMERICAN_DATE_TIME, DATE_TIME_WITH_SEC_SPACE_DIV, DEFAULT_TIMEZONE } from '../../../utils/datetimes';
import { DriverDetails, driverNameHelper } from '../helpers/dynamicDescription';
import { dynamicSourceBrokerageDescription } from '../helpers/dynamicSourceBrokerageDescription';
import CitySearch from '../../../common/CitySearch';
import { ODDateTimePicker, FORM_VARIANT } from '../../../common/ODDatePicker';
import { SharedTimeLocationRow } from '../shared/DetailCard/DetailCardTimeline';
import { DispatchingDriver, DriverEvent, CityState } from '../types';
import { ConfirmSearchAssignment } from '../shared/ConfirmSearchAssignment';
import { LOAD, CUSTOM_SOURCE_REQUEST } from '../helpers/constants';
import { EventDetailsAccordion } from '../shared/EventDetailsAccordion';

interface IconTextBoxProps {
  icon: ReactNode;
  label: ReactNode;
  description: ReactNode;
  extraStyles: SxProps;
}

const IconTextBox = ({ icon, label, description, extraStyles }: IconTextBoxProps) => (
  <Box
    sx={{
      minHeight: '40px',
      borderRadius: '4px',
      padding: '8px 16px',
      display: 'grid',
      gridTemplateColumns: '18px auto',
      gap: '23px',
      alignItems: 'center',
      ...extraStyles
    }}
  >
    {icon}
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography><strong>{label}</strong></Typography>
      {description}
    </Box>
  </Box>
);

interface CreateSourceRequestSlideoutProps {
  onClose: () => void;
  currentDriver: DispatchingDriver;
  nextDriverEvent?: DriverEvent;
  onCreateSuccess: () => void;
}

const CreateSourceRequestSlideout = ({ onClose, currentDriver, nextDriverEvent, onCreateSuccess }: CreateSourceRequestSlideoutProps) => {
  const [pickUpCity, setPickUpCity] = useState<CityState | null>(currentDriver?.avail_city
    ? { city_state: `${currentDriver.avail_city}, ${currentDriver.avail_state}`, timezone: currentDriver?.avail_timezone }
    : null
  );
  const [pickUpStart, setPickUpStart] = useState<Moment | null>(currentDriver?.avail_datetime
    ? moment.tz(currentDriver?.avail_datetime, AMERICAN_DATE_TIME, DEFAULT_TIMEZONE)
    : null
  );
  const [dropOffCity, setDropOffCity] = useState<CityState | null>(null);
  const [dropOffStart, setDropOffStart] = useState<Moment | null>(null);
  const [confirmationOpen, setOpenConfirmation] = useState(false);

  const checkIsDisabled = () => {
    if (!pickUpCity || !pickUpStart) return true;
    if (pickUpStart?.isAfter(dropOffStart) || dropOffStart?.isBefore(pickUpStart)) return true;
  };

  const createSourceRequest = () => {
    let pickUpCitySt = [] as string[];
    if (pickUpCity) pickUpCitySt = pickUpCity.city_state.split(', ');
    let dropOffCitySt = [] as string[];
    if (dropOffCity) dropOffCitySt = dropOffCity?.city_state.split(', ');

    const source_search = {
      dropoff: dropOffStart ? dropOffStart.format(DATE_TIME_WITH_SEC_SPACE_DIV) : undefined,
      dropoff_city: dropOffCitySt[0],
      dropoff_city_state: dropOffCity?.city_state,
      dropoff_state: dropOffCitySt[1],
      dropoff_tz: dropOffCity?.timezone,
      movement_type: CUSTOM_SOURCE_REQUEST,
      pickup: pickUpStart?.format(DATE_TIME_WITH_SEC_SPACE_DIV),
      pickup_city: pickUpCitySt[0],
      pickup_city_state: pickUpCity?.city_state,
      pickup_state: pickUpCitySt[1],
      pickup_tz: pickUpCity?.timezone,
      revenue: null,
      simulated_rate_per_mile: 0,
      avail_for_dispatch_et: currentDriver.avail_for_dispatch_et,
      avail_timezone: currentDriver.avail_timezone,
      predispatch_final_loc: currentDriver.avail_city
    };
    instance.post(`/dispatching/drivers/${currentDriver?.driver.driver_id}/source-request/`, { source_search })
      .then(() => {
        onCreateSuccess();
        onClose();
      }).catch((err) => console.error(err));
  };

  const timeWindow = getLocalizedTimeRange(nextDriverEvent?.start_time, nextDriverEvent?.end_time, nextDriverEvent?.timezone, AMERICAN_DATE_TIME);
  let pickUpTimeZone = '';
  let dropOffTimeZone = '';
  if (!!pickUpCity && !!pickUpStart) pickUpTimeZone = `(${getTimeZoneAcronym(pickUpStart, pickUpCity.timezone ?? '')})`;
  if (!!dropOffCity && !!dropOffStart) dropOffTimeZone = `(${getTimeZoneAcronym(dropOffStart, dropOffCity.timezone ?? '')})`;

  const datesCrossed = !!pickUpStart && !!dropOffStart && pickUpStart?.isAfter(dropOffStart);

  return (
    <ODSlideOut
      open={true}
      title="Create Source Request"
      onClose={onClose}
      sx={{ zIndex: 1300 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pb: '72px' }}>
        <Typography>Sending this manual request will move the driver to the &lsquo;Needs Sourcing&rsquo; queue along with the sourcing details below.</Typography>
        <IconTextBox
          label={driverNameHelper(true, currentDriver?.driver)}
          icon={currentDriver?.driver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />}
          description={<DriverDetails driver={currentDriver?.driver} />}
          extraStyles={{
            pl: '23px !important',
            backgroundColor: 'colors.lightBlue1',
          }}
        />
        <EventDetailsAccordion label="Pickup Details" isRequired>
          <CitySearch
            label="Enter Pick Up City"
            selectedLocations={pickUpCity}
            setSelectedLocations={setPickUpCity}
          />
          <Box>
            <InputLabel>{`Set Available for Pick Up ${pickUpTimeZone}`}</InputLabel>
            <ODDateTimePicker
              inputId="-start"
              currentValue={pickUpStart}
              changeValue={setPickUpStart}
              variant={FORM_VARIANT}
              timezone={pickUpCity?.timezone}
              isErrored={datesCrossed}
            />
            {datesCrossed && (
              <Typography sx={{ fontSize: '12px', color: 'alert.main' }}>
                Drop Off date must be after the Pick Up date
              </Typography>
            )}
          </Box>
        </EventDetailsAccordion>
        <EventDetailsAccordion label="Drop Off Details">
          {!!nextDriverEvent && (
            <Box sx={{ pb: 2, borderColor: 'level3', borderBottomStyle: 'solid', borderBottomWidth: '1px' }}>
              <InputLabel>Next Scheduled Event</InputLabel>
              <IconTextBox
                label={nextDriverEvent.event_type}
                icon={<EventNoteSharp />}
                description={<SharedTimeLocationRow location={nextDriverEvent.location} time={timeWindow} />}
                extraStyles={{
                  borderColor: 'level3',
                  borderWidth: '1px',
                  borderStyle: 'solid'
                }}
              />
            </Box>
          )}
          <CitySearch
            label="Enter Drop Off City"
            selectedLocations={dropOffCity}
            setSelectedLocations={setDropOffCity}
          />
          <Box>
            <InputLabel>{`Set Available for Drop Off ${dropOffTimeZone}`}</InputLabel>
            <ODDateTimePicker
              inputId="-start"
              currentValue={dropOffStart}
              changeValue={setDropOffStart}
              variant={FORM_VARIANT}
              timezone={pickUpCity?.timezone}
              isErrored={datesCrossed}
            />
          </Box>
        </EventDetailsAccordion>
      </Box>
      <Box
        sx={{
          backgroundColor: 'level1',
          width: '392px',
          margin: '0px -32px',
          borderTopColor: 'level3',
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          padding: '8px 32px',
          display: 'flex',
          gap: 2,
          button: { flex: 1 },
          position: 'fixed',
          bottom: 0,
          right: '32px'
        }}
      >
        <ODButton onClick={onClose}>Cancel</ODButton>
        <ODButton
          id="create-source-request-send"
          variant="blue"
          onClick={() => setOpenConfirmation(true)}
          disabled={checkIsDisabled()}
        >
          Send
        </ODButton>
      </Box>
      {confirmationOpen && (
        <ConfirmSearchAssignment
          subject={LOAD}
          assignment={dynamicSourceBrokerageDescription([{ has_source: true }], currentDriver, LOAD)}
          isOptimal={false}
          hasOptimal={false}
          isBrokerage={false}
          hasSource={true}
          isDriverPendingSourceRequest={false}
          onCancel={() => setOpenConfirmation(false)}
          onConfirm={createSourceRequest}
        />
      )}
    </ODSlideOut>
  );
};

export default CreateSourceRequestSlideout;
