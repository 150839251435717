import { GET_USER_SETTINGS, SET_USER_SETTINGS } from '../constants/actionTypes';

const initialState = {
  colorMode: 'light',
  loadAcceptanceFilters: null,
  tacticalProcurementFilters: {},
  dispatchingFilters: {},
  commitmentManagerFilters: {},
  mapTablePosition: 'Side',
  userSettingsDataLoaded: false
};

const setUserSettings = (state, { settings }) => (
  {
    ...state,
    ...settings
  }
);

const userSettingsReducer = (state = initialState, action = null) => {
  if (!action) return state;
  switch (action.type) {
    case GET_USER_SETTINGS:
      return state;
    case SET_USER_SETTINGS:
      return setUserSettings(state, action);
    default:
      return state;
  }
};

export default userSettingsReducer;
