import React, { ReactNode } from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { env } from '../env';

export const connection = (conn: string | null) => {
  if (sessionStorage.getItem('odConnection')) {
    return sessionStorage.getItem('odConnection');
  }
  if (!!conn) {
    sessionStorage.setItem('odConnection', conn);
    return conn;
  }
  if (['localhost', 'dev', 'demo', 'qa'].some((environment) => window.location.host.includes(environment))) {
    return 'optimaldynamics';
  }
  return window.location.host.split('.')[0];
};

const Auth = (props: { organization: Record<string, string>, errorMessages: string[], children: ReactNode }) => {
  const { organization, errorMessages, children } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.target);
  };

  if (!Object.keys(organization).length && !errorMessages.length) return null;

  return (
    <Auth0Provider
      domain={env.REACT_APP_DOMAIN}
      clientId={env.REACT_APP_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      organization={organization.id}
      connection={connection(searchParams.get('conn')) || undefined}
      scope={env.REACT_APP_SCOPE}
      audience={env.REACT_APP_AUDIENCE}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth;
