import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography } from '@mui/material';
import PlaceSharp from '@mui/icons-material/PlaceSharp';
import FlagSharp from '@mui/icons-material/FlagSharp';
import { ODLaneIcon } from '@OptimalDynamics/core-ai-common-ui';
import { SHORT_DATE_TIME, getLocalizedTimeRange } from '../../../utils/datetimes';
import { BROKERAGE, LOAD, DISABLE_BROKERAGE } from '../helpers/constants';
import { getOriginLabel, getDestinationLabel } from '../helpers/dispatchingScripts';
import { DetailCardTitle, DetailCardAccordion } from '../shared/DetailCard';
import { DetailCardTimeline, EventRow } from '../shared/DetailCard/DetailCardTimeline';
import { DispatchingLoad } from '../types';
import { SWITCH_TO_ASSET } from '../../loadAcceptance/loadAcceptanceConstants';
import { LoadDetails } from '../helpers/dynamicDescription';

interface SelectedLoadCardProps {
  currentLoad: DispatchingLoad;
  openLoadDetailsModal: () => void;
}

const SelectedLoadCard = ({ currentLoad, openLoadDetailsModal }: SelectedLoadCardProps) => {
  const { assignBrokerageLoads } = useFlags();

  return (
    <DetailCardTitle
      title="Load"
      id="selected-row-card-title"
      rating=""
      isCurrentRow={true}
      selected={true}
      active={true}
    >
      <DetailCardAccordion
        defaultCollapse={true}
        id="selected-row-card-accordion"
        identifier={{
          icon: <ODLaneIcon />,
          label: currentLoad?.load_id,
          description: <LoadDetails load={currentLoad} />
        }}
        selected={true}
        type={LOAD}
        onClickId={openLoadDetailsModal}
        warningReason={currentLoad?.internal_source === BROKERAGE ? (assignBrokerageLoads ? SWITCH_TO_ASSET : DISABLE_BROKERAGE) : undefined}
      >
        <DetailCardTimeline selected={true}>
          <EventRow icon={<PlaceSharp />}>
            <Typography>
              <strong>
                {getOriginLabel(currentLoad?.orig_live_flag)}
              </strong>
              <br />
              {currentLoad?.pickup_location}
              {(() => {
                const window = getLocalizedTimeRange(currentLoad?.pickup_start_et, currentLoad?.pickup_end_et, currentLoad?.pickup_timezone, SHORT_DATE_TIME);
                if (window) return `: ${window}`;
              })()}
            </Typography>
          </EventRow>
          <EventRow icon={<FlagSharp />}>
            <Typography>
              <strong>
                {getDestinationLabel(currentLoad?.dest_drop_flag)}
              </strong>
              <br />
              {currentLoad?.dropoff_location}
              {(() => {
                const window = getLocalizedTimeRange(currentLoad?.dropoff_start_et, currentLoad?.dropoff_end_et, currentLoad?.dropoff_timezone, SHORT_DATE_TIME);
                if (window) return `: ${window}`;
              })()}
            </Typography>
          </EventRow>
        </DetailCardTimeline>
      </DetailCardAccordion>
    </DetailCardTitle>
  );
};

export default SelectedLoadCard;
