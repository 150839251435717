import React, { ReactNode, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ODAvatar } from '@OptimalDynamics/core-ai-common-ui';
import ExpandLessSharp from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import ManageSearchSharp from '@mui/icons-material/ManageSearchSharp';

interface EventDetailsAccordionProps {
  label: string;
  isRequired?: boolean;
  children: ReactNode;
}

export const EventDetailsAccordion = ({ label, children, isRequired = false }: EventDetailsAccordionProps) => {
  const [open, isOpen] = useState(true);

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderColor: 'level3',
        borderRadius: '4px',
        borderWidth: '1px',
        borderStyle: 'solid'
      }}
    >
      <Box sx={{ display: 'grid', gridTemplateColumns: '32px auto 32px', gap: 2, alignItems: 'center' }}>
        <ManageSearchSharp sx={{ justifySelf: 'center' }} />
        <Typography>
          <strong>{label}</strong>
          {isRequired && <span style={{ fontStyle: 'italic' }}>&nbsp;(Required)</span>}
        </Typography>
        <ODAvatar variant="transparent" size="small" onClick={() => isOpen(!open)}>
          {open ? <ExpandLessSharp /> : <ExpandMoreSharp />}
        </ODAvatar>
      </Box>
      {open && children}
    </Box>
  );
};
