import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography } from '@mui/material';
import FlagSharp from '@mui/icons-material/FlagSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import moment from 'moment-timezone';
import {
  DetailCardTimeline,
  AvailableTimeRow,
  HomeRow,
  LocationRow,
  StopRow,
  Mileage,
  generateTimestamp,
  EventRow,
  EstimatedTime
} from '../shared/DetailCard/DetailCardTimeline';
import { getLocalizedTimeRange } from '../../../utils/datetimes';
import {
  DROPOFF,
  EMPTY_TO_HOME,
  HOME,
  LOADED,
  PICKUP,
  RECOMMENDED_HOME,
  SCHEDULED_HOME,
  STOP
} from '../helpers/constants';
import { getOriginLabel, getDestinationLabel } from '../helpers/dispatchingScripts';
import { LoadsSlideoutCardTimelineProps, Submove } from '../types';

export const LoadsSlideoutCardTimeline = ({ selected, currentDriver, loadOption, showAvailable, openHosDialog, openExplainPtaDialog, isForecasted = false }: LoadsSlideoutCardTimelineProps) => {
  const { showSourceSuggestions, usePlannedTimeAtHomeForHomeMoves } = useFlags();

  if (!currentDriver) return null;
  const isSource = showSourceSuggestions && loadOption.has_source;
  const sourceSubmove = isSource ? loadOption?.submoves?.find((move) => !!move?.source_search)?.source_search : null;

  const renderSubmoves = (submoves: Submove[]) => (
    <>
      {submoves[0].type === LOADED && (
        <LocationRow
          label={getOriginLabel(loadOption.orig_live_flag, isSource, isForecasted)}
          start={loadOption.pickup_start_time}
          end={loadOption.pickup_end_time}
          estimates={isSource
            ? {
              EPU: generateTimestamp(sourceSubmove?.pickup, sourceSubmove?.pickup_tz)
            } : isForecasted ? {
              EPU: moment(loadOption.pickup_est_loading_start_time, 'YYYY-MM-DD HH:mm:ss').tz(loadOption.pickup_timezone).format('MM/DD HH:mm z')
            } : {
              ELT: getLocalizedTimeRange(loadOption.pickup_est_loading_start_time, loadOption.pickup_est_loading_end_time, loadOption.pickup_timezone),
              ETD: generateTimestamp(loadOption.est_pickup_time, loadOption.pickup_timezone)
            }}
          timezone={loadOption.pickup_timezone}
          location={isForecasted ? loadOption.pickup_location.slice(0, 3) : `${loadOption.pickup_city}, ${loadOption.pickup_state}`}
          mileage={loadOption.deadhead_miles}
          hideAppointment={isSource}
        />
      )}
      {submoves.map((move) => {
        switch (move.end_location_type) {
          case PICKUP:
            return (
              <LocationRow
                label={getOriginLabel(loadOption.orig_live_flag, isSource, isForecasted)}
                start={loadOption.pickup_start_time}
                end={loadOption.pickup_end_time}
                estimates={isSource ? {
                  EPU: generateTimestamp(sourceSubmove?.pickup, sourceSubmove?.pickup_tz)
                } : isForecasted ? {
                  EPU: moment(loadOption.pickup_est_loading_start_time, 'YYYY-MM-DD HH:mm:ss').tz(loadOption.pickup_timezone).format('MM/DD HH:mm z')
                } : {
                  ELT: getLocalizedTimeRange(loadOption.pickup_est_loading_start_time, loadOption.pickup_est_loading_end_time, move.end_timezone),
                  ETD: generateTimestamp(loadOption.est_pickup_time, move.end_timezone)
                }}
                timezone={move.end_timezone}
                location={isForecasted ? move.end_location.slice(0, 3) : move.end_city_state}
                mileage={move.distance}
                hideAppointment={isSource}
                hideHour={isForecasted}
              />
            );
          case DROPOFF:
            return (
              <LocationRow
                label={getDestinationLabel(loadOption.dest_drop_flag, isSource, isForecasted)}
                start={loadOption.dropoff_start_time}
                end={loadOption.dropoff_end_time}
                estimates={isSource ? {
                  EDO: generateTimestamp(sourceSubmove?.dropoff, sourceSubmove?.dropoff_tz)
                } : isForecasted ? {
                  EDO: moment(loadOption.est_dropoff_time, 'YYYY-MM-DD HH:mm:ss').tz(loadOption.dropoff_timezone).format('MM/DD HH:mm z') 
                } : {
                  ETA: generateTimestamp(loadOption.est_dropoff_time, move.end_timezone),
                  EULT: getLocalizedTimeRange(loadOption.dropoff_est_unloading_start_time, loadOption.dropoff_est_unloading_end_time, move.end_timezone)
                }}
                timezone={move.end_timezone}
                location={isForecasted ? move.end_location.slice(0, 3) : move.end_city_state}
                mileage={move.distance}
                icon={<FlagSharp />}
                hideAppointment={isSource}
                hideHour={isForecasted}
              />
            );
          case HOME:
          case SCHEDULED_HOME:
          case RECOMMENDED_HOME:
            if (move.type.includes(HOME)) {
              const type = move.end_location_type;
              return (
                <HomeRow
                  label={`${type === SCHEDULED_HOME ? 'Scheduled ' : type === RECOMMENDED_HOME ? 'Recommended ' : ''}Home Time`}
                  time={usePlannedTimeAtHomeForHomeMoves ? move.planned_tah_start_time : move.start_time}
                  timezone={move.start_timezone}
                  location={isForecasted ? move.start_location.slice(0, 3) : move.start_city_state}
                  timeWindow={isSource
                    ? getLocalizedTimeRange(sourceSubmove?.pickup, sourceSubmove?.dropoff, sourceSubmove?.pickup_tz)
                    : usePlannedTimeAtHomeForHomeMoves 
                      ? getLocalizedTimeRange(move.planned_tah_start_time, move.planned_tah_end_time, move.start_timezone) : getLocalizedTimeRange(move.start_time, move.end_time, move.start_timezone)}
                />
              );
            }
            return <Mileage mileage={move.distance} />;
          case STOP:
            return (
              <StopRow
                estimate={move.end_time}
                timezone={move.end_timezone}
                location={isForecasted ? move.end_location.slice(0, 3) : move.end_city_state}
                mileage={move.distance}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );

  const renderClassic = () => {
    if (loadOption.movement_type === EMPTY_TO_HOME) {
      let location = '';
      if (loadOption.destination_city && loadOption.destination_state) {
        location = isForecasted ? loadOption.dropoff_location.slice(0, 3) : `${loadOption.destination_city}, ${loadOption.destination_state}`;
      }

      return (
        <>
          <Mileage mileage={loadOption.deadhead_miles} />
          <HomeRow
            label="Home Time"
            time={loadOption.est_dropoff_time}
            timezone={loadOption.dropoff_timezone}
            location={location}
          />
        </>
      );
    }

    const isFlexible = isSource && (!loadOption?.destination_city || !loadOption?.source_dropoff);

    return (
      <>
        <LocationRow
          label={getOriginLabel(loadOption.orig_live_flag, isSource, isForecasted)}
          start={loadOption.pickup_start_time}
          end={loadOption.pickup_end_time}
          estimates={isSource ? {
            EPU: generateTimestamp(loadOption.source_pickup, loadOption.pickup_timezone)
          } : isForecasted ? {
            EPU: moment(loadOption.est_pickup_time, 'YYYY-MM-DD HH:mm:ss').tz(loadOption.pickup_timezone).format('MM/DD HH:mm z') 
          } : {
            ETD: generateTimestamp(loadOption.est_pickup_time, loadOption.pickup_timezone)
          }}
          timezone={loadOption.pickup_timezone}
          location={isForecasted ? loadOption.pickup_location.slice(0, 3) : `${loadOption.pickup_city}, ${loadOption.pickup_state}`}
          mileage={showAvailable ? loadOption.deadhead_miles : undefined}
          hideAppointment={isSource}
          hideHour={isForecasted}
        />
        {isFlexible ? (
          <EventRow icon={<InfoOutlined />} purpose="flexible">
            <Typography><strong>{getDestinationLabel(loadOption.dest_drop_flag, isSource, isForecasted)}</strong></Typography>
            {(!loadOption?.destination_city && !loadOption?.source_dropoff) && (
              <Typography>Flexible Location &amp; Time</Typography>
            )}
            {(loadOption?.destination_city && !loadOption?.source_dropoff) && (
              <Typography>{`${loadOption.destination_city}, ${loadOption.destination_state}`}</Typography>
            )}
            {(loadOption?.source_dropoff && !loadOption?.destination_city) && (
              <EstimatedTime estimates={{ EDO: generateTimestamp(loadOption.source_dropoff, loadOption.dropoff_timezone) }} />
            )}
          </EventRow>
        ) : (
          <LocationRow
            label={getDestinationLabel(loadOption.dest_drop_flag, isSource, isForecasted)}
            start={loadOption.dropoff_start_time}
            end={loadOption.dropoff_end_time}
            estimates={isSource ? {
              EDO: generateTimestamp(loadOption.source_dropoff, loadOption.dropoff_timezone)
            } : isForecasted ? {
              EDO: moment(loadOption.est_dropoff_time, 'YYYY-MM-DD HH:mm:ss').tz(loadOption.dropoff_timezone).format('MM/DD HH:mm z') 
            } : {
              ETA: generateTimestamp(loadOption.est_dropoff_time, loadOption.dropoff_timezone)
            }}
            timezone={loadOption.dropoff_timezone}
            location={isForecasted ? loadOption.dropoff_location.slice(0, 3) : `${loadOption.destination_city}, ${loadOption.destination_state}`}
            mileage={loadOption.loaded_miles}
            icon={<FlagSharp />}
            hideAppointment={isSource}
            hideHour={isForecasted}
          />
        )}
      </>
    );
  };

  return (
    <DetailCardTimeline selected={selected} isSource={isSource}>
      {showAvailable && (
        <AvailableTimeRow
          available={currentDriver.avail_for_dispatch_et}
          timezone={currentDriver.avail_timezone}
          hos={currentDriver.hos_status}
          openHosDialog={openHosDialog}
          openExplainPtaDialog={openExplainPtaDialog}
          location={currentDriver.predispatch_final_loc}
        />
      )}

      {loadOption?.submoves?.length > 0 ? renderSubmoves(loadOption.submoves) : renderClassic()}
    </DetailCardTimeline>
  );
};
