import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Avatar, Typography } from '@mui/material';
import AlarmOnSharp from '@mui/icons-material/AlarmOnSharp';
import CheckCircleOutlineSharp from '@mui/icons-material/CheckCircleOutlineSharp';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import RuleSharp from '@mui/icons-material/RuleSharp';
import { ODMenu } from '@OptimalDynamics/core-ai-common-ui';
import moment from 'moment-timezone';
import { HOSIcon } from '../../../common/icons';
import { DetailCardTitle, DetailCardAccordion } from '../shared/DetailCard';
import { DetailCardTimeline, EventRow, generateTimestamp } from '../shared/DetailCard/DetailCardTimeline';
import { DispatchingDriver } from '../types';
import { DO_NOT_LINK } from '../helpers/constants';
import { driverNameHelper, DriverDetails } from '../helpers/dynamicDescription';
import { DEFAULT_TIMEZONE } from '../../../utils/datetimes';

interface SelectedDriverCardProps {
  currentDriver: DispatchingDriver;
  onClickHos: () => void;
  onClickNextEvent?: () => void;
  onClickRules: () => void;
  openExplainPtaDialog?: (_event: React.MouseEvent) => void;
}

const SelectedDriverCard = ({ currentDriver, onClickHos, onClickRules, onClickNextEvent, openExplainPtaDialog }: SelectedDriverCardProps) => {
  const { addDriverNameUi, showSourceSuggestions, driverMenuDropdown } = useFlags();

  return (
    <DetailCardTitle
      title="Driver"
      id="selected-row-card-title"
      rating=""
      isCurrentRow={true}
      selected={true}
      active={true}
      sourceHours={!!currentDriver?.source_search ? moment.tz(currentDriver?.source_search.pickup, DEFAULT_TIMEZONE).diff(moment(), 'hours') : undefined}
    >
      <DetailCardAccordion
        defaultCollapse={true}
        id="selected-row-card-accordion"
        identifier={{
          icon: (currentDriver?.has_source_request && showSourceSuggestions)
            ? <Avatar sx={{ backgroundColor: 'colors.neutral6', width: 32, height: 32 }}><ConnectWithoutContactSharp /></Avatar>
            : currentDriver?.driver.is_sleeper
              ? <PeopleSharp />
              : <PersonSharp />,
          label: driverNameHelper(addDriverNameUi, currentDriver?.driver),
          description: addDriverNameUi ? <DriverDetails driver={currentDriver?.driver} /> : currentDriver?.driver?.driver_type
        }}
        selected={true}
        type={DO_NOT_LINK}
        onClickHos={onClickHos}
        onClickRules={onClickRules}
        menu={driverMenuDropdown && (
          <ODMenu
            id="driver-dropdown-menu"
            menuIcon={<MoreVertSharpIcon />}
            menuOptions={[
              { label: 'HOS Clocks', icon: <HOSIcon />, onClick: onClickHos },
              { label: 'Driver Rules', icon: <RuleSharp />, onClick: onClickRules }
            ]}
          />
        )}
        onClickNextEvent={onClickNextEvent}
      >
        <DetailCardTimeline selected={true}>
          <EventRow purpose="scheduled" icon={<CheckCircleOutlineSharp />}>
            <Typography>
              <strong>
                {`(${currentDriver?.planned_options_count}) Planned Load${currentDriver?.planned_options_count === 1 ? '' : 's'}`}
              </strong>
              <br />
              {!!currentDriver?.current_location && `Currently: @ ${currentDriver?.current_location}`}
            </Typography>
          </EventRow>
          <EventRow purpose="available" icon={<AlarmOnSharp />} openExplainPtaDialog={openExplainPtaDialog}>
            <Typography>
              <strong>Available For Dispatch</strong>
              <br />
              {currentDriver?.predispatch_final_loc && `${currentDriver?.predispatch_final_loc}: `}
              {generateTimestamp(currentDriver?.avail_for_dispatch_et, currentDriver?.avail_timezone)}
            </Typography>
          </EventRow>
        </DetailCardTimeline>
      </DetailCardAccordion>
    </DetailCardTitle>
  );
};

export default SelectedDriverCard;
