/* eslint-disable @typescript-eslint/no-shadow */
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash';
import { Box, Chip, Typography } from '@mui/material';
import OpenInNewSharp from '@mui/icons-material/OpenInNewSharp';
import ViewListSharp from '@mui/icons-material/ViewListSharp';
import MailOutlineSharp from '@mui/icons-material/MailOutlineSharp';
import ArrowForward from '@mui/icons-material/ArrowForward';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import SearchSharp from '@mui/icons-material/SearchSharp';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import BadgeSharp from '@mui/icons-material/BadgeSharp';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  ODAvatar,
  ODDialog,
  ODButton,
  ODRadio,
  ODTable,
  ODTableBody,
  ODTableCell,
  ODTableContainer,
  ODTableHead,
  ODTableRow,
  ODTooltip
} from '@OptimalDynamics/core-ai-common-ui';
import { useDispatch, useSelector } from 'react-redux';
import instance from '../../../utils/axios_instance';
import { SearchContext } from '../../../utils/context/searchContext';
import ColumnHeaderSettings from '../../../common/ColumnHeaderSettings';
import { DispatchingDriver, DispatchingHeader } from '../types';
import {
  LOAD,
  PICKUP_RADIUS,
  DROPOFF_PREFIX,
  DROPOFF_RADIUS,
  WEIGHT,
  AGE_IN_MINUTES,
  SOURCE_DEETS,
  PICKUP_PREFIX,
  EQUIPMENT_TYPES_COL,
  DROPOFF_LOCATION,
  PICKUP_LOCATION,
  CUSTOM_SOURCE_REQUEST,
  initialSourceColumns,
  initialSourceColumnsWithFeasibilityChecker,
  HIDDEN_LOAD_SEARCH_HEADERS,
} from '../helpers/constants';
import { RootState } from '../../../store/reducers';
import { UpdateUserSettings } from '../../../store/actions';
import { SessionContext } from '../../../utils/context/sessionCtx';
import { DriverIdentifier, dynamicSourceBrokerageDescription } from '../helpers/dynamicSourceBrokerageDescription';
import { ConfirmSearchAssignment } from '../shared/ConfirmSearchAssignment';
import PermitsAndRequirementsDialog from '../shared/PermitsAndRequirementsDialog';
import { dynamicDescription } from '../helpers/dynamicDescription';
import EmailViewer from '../shared/EmailViewer';
import { ExternalLoad, Search, SearchParams, ExternalSearchTableColumn, PermitsDialogInfo } from './types';
import { formatData } from './utils';
import HOSBreakdown from '../components/HOSBreakdownSlideout';
import { getLocalizedIsoTime } from '../../../utils/datetimes';

const LoadsSearchExternal = ({ lastLocationZip = '', currentDriver, handleModalClose, setShowSuccessSnackBar }: { lastLocationZip?: string, currentDriver: DispatchingDriver, handleModalClose: () => void, setShowSuccessSnackBar: ((_tf: boolean) => void) | undefined }) => {
  const { rowsPerPage, search, setLoading, setHasResults } = useContext(SearchContext);
  const { sessionUid } = useContext(SessionContext);
  const { noExternalLoadsLimit, useSourceFeasibilityFeature, useFeasibilityFastFollow, tableSettingsUpdate } = useFlags();
  const [results, setResults] = useState<ExternalLoad[]>([]);
  const [columns, setColumns] = useState(cloneDeep(useSourceFeasibilityFeature ? initialSourceColumnsWithFeasibilityChecker : initialSourceColumns));
  const [emailUrl, setEmailUrl] = useState<string | null | undefined>(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedLoad, setSelectedLoad] = useState<ExternalLoad | null>(null);
  const [confirmAssignmentOpen, setConfirmAssignmentOpen] = useState(false);
  const [permitsDialogData, setPermitsDialogData] = useState<PermitsDialogInfo | null>(null);
  const userSettings = useSelector((state: RootState) => state.userSettings);
  const [currOpenHOSBreakdown, setCurrOpenHOSBreakdown] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let columns;
    if (tableSettingsUpdate) {
      columns = cloneDeep(useSourceFeasibilityFeature ? initialSourceColumnsWithFeasibilityChecker : initialSourceColumns).map((col) => {
        if (userSettings[HIDDEN_LOAD_SEARCH_HEADERS].includes(col.value)) col.visible = false;
        else col.visible = true;
        return col;
      });
    } else {
      columns = useSourceFeasibilityFeature 
        ? userSettings?.loadsSearchExternalColumnsV2 
        : userSettings?.loadsSearchExternalColumns;
    }
  
    if (columns) {
      setColumns(columns);
    }
  }, [userSettings, useSourceFeasibilityFeature]);
  
  useEffect(() => {
    const searchTyped = search as Search;
    const params: SearchParams = searchTyped.params;
    if (!params) return;
    const { pickUpStart, pickUpEnd, dropOffStart, dropOffEnd } = params;

    const request = {
      driver_id: searchTyped?.driverPk,
      engine_run_id: searchTyped?.engineRunId,
      origin: params?.pickUpCity,
      origin_radius: params?.pickUpRadius,
      destination_radius: params?.dropOffRadius,
      destination: params?.dropOffCity,
      movement_id: searchTyped?.movementId,
      submove_id: searchTyped?.submoveId,
      pickup: pickUpStart?.millisecond(0)?.second(0), // query will not work if it has (m)sec
      dropoff: dropOffStart?.millisecond(0)?.second(0), // query will not work if it has (m)sec
      ...(!noExternalLoadsLimit && { limit: rowsPerPage }),
      pickup_duration: undefined as unknown as number,
      dropoff_duration: undefined as unknown as number,
      search_session_uid: sessionUid,
      ...(!!lastLocationZip && { last_location_zip: lastLocationZip }),
      ...(params?.equipmentTypes?.length > 0 && { equipment_types: params.equipmentTypes }), 
      ...(params?.minRatePerMile !== null && { min_rate_per_mile: params.minRatePerMile }),
      ...(params?.weight !== null && { max_weight_lbs: params.weight }),
      ...(params?.ageInDays !== null && { first_discovered_minutes: params.ageInDays * 1440 }),
    };

    // Duration needs to be in *minutes* so we're getting the difference in minutes
    if (!!pickUpStart && !!pickUpEnd) request['pickup_duration'] = pickUpEnd.diff(pickUpStart, 'minutes');
    if (!!dropOffStart && !!dropOffEnd) request['dropoff_duration'] = dropOffEnd.diff(dropOffStart, 'minutes');

    setLoading(true);
    instance
      .post('/loads/external/', request)
      .then((res) => {
        const response = res.data.loads;
        setResults(response);
        setHasResults(response?.length > 0);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [search]);

  const updateHeaders = (header: DispatchingHeader) => {
    const tempUserSettings = cloneDeep(userSettings);
    if (tableSettingsUpdate) {
      const idx = tempUserSettings[HIDDEN_LOAD_SEARCH_HEADERS]?.indexOf(header.value);
      if (!idx || idx > -1) {
        tempUserSettings[HIDDEN_LOAD_SEARCH_HEADERS]?.splice(idx, 1);
      } else {
        tempUserSettings[HIDDEN_LOAD_SEARCH_HEADERS] = [...(tempUserSettings[HIDDEN_LOAD_SEARCH_HEADERS] ?? []), header.value];
      }
      dispatch(UpdateUserSettings(tempUserSettings));
    } else {
      const colsUpdated = columns.map((item) => {
        if (item.label === header.label) {
          item.visible = !item.visible;
        }
        return item; 
      });
      setColumns(colsUpdated);
      const updatedObj = useSourceFeasibilityFeature ? { loadsSearchExternalColumnsV2: colsUpdated } : { loadsSearchExternalColumns: colsUpdated };
      dispatch(UpdateUserSettings({ ...userSettings, ...updatedObj }));
    }
  };

  const visibleColumns = columns.filter((header) => header.visible);
  const srcColStartIdx = visibleColumns.findIndex((header) => header.parent === SOURCE_DEETS);
  const visibleAdditionalLoadDetailsColsLens = columns.filter((header) => header.visible && header.parent === SOURCE_DEETS).length;
  const loadDetailsColumns = visibleColumns.slice(1, srcColStartIdx);
  const sourceDetailsColumns = visibleColumns.slice(srcColStartIdx);

  const customStyledTableHeaderSx = {
    overflow: 'hidden',
    '&:first-of-type': { 
      position: 'sticky',
      left: 0,
      zIndex: 4,
      backgroundColor: 'inherit',
      pl: 2,
      pr: 2,
      borderRight: 1
    },
    '&:nth-of-type(2)': { 
      pl: 2,
    },
    [`&:nth-last-child(-n+${visibleAdditionalLoadDetailsColsLens})`]: {
      ...useSourceFeasibilityFeature ? {} : {
        position: 'sticky',
        pr: 0,
        right: `0px !important`,
        zIndex: 4,
        backgroundColor: 'inherit',
      }
    } };

  const renderDataCell = (
    load: ExternalLoad,
    field: string,
    width: number,
    useCellTooltip: boolean,
    columns: ExternalSearchTableColumn[],
    index: number,
    setSelectedRow: Dispatch<SetStateAction<ExternalLoad | null>>,
    selectedRow: ExternalLoad | null,
    setEmailUrl: Dispatch<SetStateAction<string | null | undefined>>,
    useSourceFeasibilityFeature: boolean
  ) => {
    const datum = formatData(load, field, setEmailUrl, setCurrOpenHOSBreakdown);
    const right = columns
      .filter((col) => col.visible)
      .slice(index + 1)
      .reduce((prev, curr) => prev + curr.width, 0);
    const firstVisibleColumn = columns.find((col) => col.visible);
    const getFeasibleLabel = () => {
      if (field === 'pickup') {
        if (load.pickup_feasible === 'false') return 'N';
        if (load.pickup_feasible === 'true') return 'OT';
        return 'NA';
      } 
      if (field === 'dropoff') {
        if (load.dropoff_feasible === 'false') return 'N';
        if (load.dropoff_feasible === 'true') return 'OT';
        return 'NA';
      }
      return null;
    };
    
    const feasibleLabel = useSourceFeasibilityFeature ? getFeasibleLabel() : null;
    
    const chipStyles = feasibleLabel && {
      N: { backgroundColor: 'colors.accessLightRed' },
      OT: { backgroundColor: 'colors.accessLightGreen' },
      NA: { backgroundColor: 'colors.neutral2' },
    }[feasibleLabel] || {};

    const tooltipText = feasibleLabel === 'N'
      ? 'Driver is estimated to be either late or too early.'
      : feasibleLabel === 'OT'
        ? 'Driver is estimated to arrive on time.'
        : 'On time analysis not available.';
    
    const tooltipTextNew = (() => {
      if (field === 'pickup') {
        return feasibleLabel === 'N'
          ? `Not On Time | Estimated to start loading at ${getLocalizedIsoTime(load?.expected_loading_start, load?.pickup_timezone)}`
          : feasibleLabel === 'OT'
            ? `On Time | Estimated to start loading at ${getLocalizedIsoTime(load?.expected_loading_start, load?.pickup_timezone)}`
            : 'On time analysis not available.';
      }
      if (field === 'dropoff') {
        return feasibleLabel === 'N'
          ? `Not On Time | Estimated to start unloading at ${getLocalizedIsoTime(load?.expected_delivery_start, load?.dropoff_timezone)}`
          : feasibleLabel === 'OT'
            ? `On Time | Estimated to start unloading at ${getLocalizedIsoTime(load?.expected_delivery_start, load?.dropoff_timezone)}`
            : 'On time analysis not available.';
      }
      return '';
    })();
    
    const renderChip = feasibleLabel ? (
      <ODTooltip placement="top" title={useFeasibilityFastFollow ? tooltipTextNew : tooltipText}>
        <Chip
          size="small"
          label={feasibleLabel}
          sx={{
            mr: 1,
            fontSize: '14px',
            height: '24px',
            padding: '2px',
            ...chipStyles,
          }}
        />
      </ODTooltip>
    ) : null;
    
    return (
      <ODTableCell
        sx={{
          width: `${width}px`,
          maxWidth: `${width}px`,
          minWidth: `${width}px`,
          '&:nth-child(1)': { 
            position: 'sticky',
            left: 0,
            zIndex: 3,
            backgroundColor: 'inherit',
            borderRight: 1,
            borderRightColor: 'level3',
          },
          '&:nth-child(2)': { 
            pl: 2,
          },
          [`&:nth-last-child(-n+${visibleAdditionalLoadDetailsColsLens})`]: {
            ...useSourceFeasibilityFeature ? {} : {
              position: 'sticky',
              pr: 0,
              right: `${right}px !important`,
              zIndex: 3,
              backgroundColor: 'inherit',
            }
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {(field === firstVisibleColumn?.value) && (
            <ODRadio
              onClick={() => setSelectedRow(selectedRow?.load_id === load.load_id ? null : load)}
              checked={selectedRow?.load_id === load.load_id}
              sx={{ mr: 1 }}
            />
          )}
          {(useCellTooltip && datum !== '-') ? (
            <ODTooltip title={datum}>
              <div>
                {renderChip}
                {datum}
              </div>
            </ODTooltip>
          ) : (
            <>
              {renderChip}
              {datum}
            </>
          )}
        </Box>
      </ODTableCell>
    );
  };

  const getAssignmentBarContents = () => (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
      <Box
        sx={{
          width: 'auto',
          display: 'flex',
          alignItems: 'center',
          columnGap: '8px',
        }}
      >
        {currentDriver?.driver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />}
        <DriverIdentifier driver={currentDriver?.driver} />
        <ArrowForward sx={{ mx: 1 }} />
        <SearchSharp />
        <Typography noWrap maxWidth={112}>{selectedLoad?.load_id}</Typography>
      </Box>
      <Box sx={{ display: 'flex', columnGap: 2 }}>
        <ODButton
          id="view-equipment-btn"
          startIcon={<BadgeSharp />}
          onClick={() => setPermitsDialogData({ equipment: selectedLoad?.equipment_types.join(', ') })}
        >
          View Equipment Check
        </ODButton>
        {selectedLoad?.is_email ? (
          <ODButton
            id="view-email-details-btn"
            variant="blue"
            startIcon={<MailOutlineSharp />}
            onClick={() => setEmailUrl(selectedLoad?.email_uri)}
          >
            View Email Details
          </ODButton>
        ) : (
          <>
            <ODButton
              id="external-source-btn"
              variant="blue"
              startIcon={<OpenInNewSharp />}
              onClick={() => window.open(selectedLoad?.book_link, '_blank')!.focus()}
            >
              External Source Link
            </ODButton>
            <ODButton
              id="search-send-request-btn"
              variant="blue"
              startIcon={<ConnectWithoutContactSharp />}
              onClick={() => setConfirmAssignmentOpen(true)}
            >
              Send Source Request
            </ODButton>
          </>
        )}
      </Box>
    </Box>
  );

  const renderColumnContent = (col: {label: string, value: string, tooltip?: string}) => {
    const columnsWithFilters = [
      PICKUP_LOCATION,
      PICKUP_PREFIX,
      DROPOFF_LOCATION,
      DROPOFF_PREFIX,
      PICKUP_RADIUS,
      DROPOFF_RADIUS,
      EQUIPMENT_TYPES_COL,
      AGE_IN_MINUTES,
      WEIGHT,
    ];
    
    return (
      <Box display="flex" alignItems="center">
        {col.tooltip ? (
          <ODTooltip title={col.tooltip}>
            <span>{col.label}</span>
          </ODTooltip>
        ) : col.label}
        {columnsWithFilters.includes(col.value) && (
        <ODTooltip title="This column can be filtered using the ‘Adjust Search’ fields on the left.">
          <FilterListIcon sx={{ marginLeft: '8px' }} />
        </ODTooltip>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: 'max-content',
      }}
    >
      {!!selectedLoad && (
        <Box
          sx={{
            backgroundColor: 'level1',
            border: '1px solid',
            borderColor: 'level3',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            padding: '8px 16px',
            height: '40px',
            mb: 2
          }}
        >
          {getAssignmentBarContents()}
        </Box>
      )}
      <ODTableContainer
        sx={{
          height: results?.length === 0 ? 'max-content' : selectedLoad ? 'calc(100vh - 194px)' : 'calc(100vh - 121px)',
          '& .MuiTableCell-root > *': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          },
          [`& .MuiTableRow-root > .MuiTableCell-root:nth-last-of-type(${visibleAdditionalLoadDetailsColsLens}):not([colspan])`]: {
            ...useSourceFeasibilityFeature ? {} : {
              borderLeft: 1,
              borderLeftColor: 'level3',
              px: 2
            }
          },
          '& .MuiTableRow-root > .MuiTableCell-root:last-of-type:not([colspan])': {
            borderLeft: useFeasibilityFastFollow ? 1 : 'none',
            borderLeftColor: useFeasibilityFastFollow ? 'level3' : 'none',
          },
          '& .MuiTableRow-root > :nth-last-of-type(2)': {
            ...useSourceFeasibilityFeature ? {} : { pr: 'inherit' }
          },
        }}
      >
        <ODTable stickyHeader>
          <ODTableHead>
            <ODTableRow>
              <ODTableCell
                sx={useSourceFeasibilityFeature ? customStyledTableHeaderSx : { position: 'sticky', left: 0, zIndex: 5 }}
                colSpan={1}
              >
                Source Link
              </ODTableCell>
              <ODTableCell
                colSpan={loadDetailsColumns.length}
                sx={useSourceFeasibilityFeature ? customStyledTableHeaderSx : { pl: 2, position: 'sticky', left: '182px' }}
              >
                Load Details
              </ODTableCell>
              <ODTableCell
                colSpan={sourceDetailsColumns.length}
                sx={useSourceFeasibilityFeature ? customStyledTableHeaderSx : { pl: 2, borderLeft: 1, borderLeftColor: 'level2', position: 'sticky', right: 0, zIndex: 4 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: '-12px' }}>
                  {useSourceFeasibilityFeature ? 'Additional Load Details' : 'Source Details' }
                </Box>
              </ODTableCell>
              <ODTableCell
                colSpan={1}
                sx={{ pl: 1, borderLeft: 1, borderLeftColor: 'level2', position: 'sticky', right: 0, zIndex: 4 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                  <ODAvatar size="small" variant="transparent" onClick={() => setSettingsOpen(true)} sx={{ right: 0, position: 'sticky' }}>
                    <ViewListSharp />
                  </ODAvatar>
                </Box>
              </ODTableCell>
            </ODTableRow>
            <ODTableRow>
              <> 
                {visibleColumns.map((col, index) => {
                  const right = visibleColumns.slice(index + 1).reduce((prev, curr) => prev + curr.width, 0);
                  return (
                    <ODTableCell
                      sx={{
                        width: `${col.width}px`,
                        maxWidth: `${col.width}px`,
                        minWidth: `${col.width}px`,
                        overflow: 'hidden',
                        '&:first-of-type': {
                          position: 'sticky',
                          left: 0,
                          zIndex: 4,
                          backgroundColor: 'inherit',
                          pl: 2,
                          pr: 2,
                          borderRight: 1,
                          borderRightColor: 'level3'
                        },
                        '&:nth-of-type(2)': { pl: 2 },
                        [`&:nth-last-child(-n+${visibleAdditionalLoadDetailsColsLens})`]: {
                          ...useSourceFeasibilityFeature ? {} : {
                            position: 'sticky',
                            pr: 0,
                            right: `${right}px !important`,
                            zIndex: 4,
                            backgroundColor: 'inherit',
                          }
                        },
                      }}
                    >
                      {useSourceFeasibilityFeature ? renderColumnContent(col) : col.label}
                    </ODTableCell>
                  );
                })}
                {useFeasibilityFastFollow && (
                <ODTableCell sx={{
                  position: 'sticky',
                  pr: 0,
                  right: `0px !important`,
                  zIndex: 4,
                  backgroundColor: 'inherit',
                }}
                />
                )}
              </>
            </ODTableRow>
          </ODTableHead>
          <ODTableBody>
            {results.map((load) => (
              <ODTableRow selected={selectedLoad?.load_id === load?.load_id} onClick={() => setSelectedLoad(selectedLoad?.load_id === load.load_id ? null : load)}>
                {visibleColumns.map(
                  ({ value, width, useCellTooltip }, index) => renderDataCell(load, value, width, useCellTooltip, columns, index, setSelectedLoad, selectedLoad, setEmailUrl, useSourceFeasibilityFeature)
                )}
                {useFeasibilityFastFollow && (
                  <ODTableCell sx={{ display: 'flex',
                    borderLeft: 1,
                    borderLeftColor: 'level2',
                    padding: 0,
                    alignItems: 'center',
                    justifyContent: 'center', 
                    position: 'sticky',
                    pr: 0,
                    right: `0px !important`,
                    zIndex: 3,
                    backgroundColor: 'inherit',
                  }}
                  />
                )}
              </ODTableRow>
            ))}
          </ODTableBody>
        </ODTable>
      </ODTableContainer>
      <ColumnHeaderSettings
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        // @ts-expect-error it's complaining that a child class has insufficient overlap with the parent
        onToggle={(col) => updateHeaders(col)}
        columns={columns}
        onReset={() => tableSettingsUpdate
          ? dispatch(UpdateUserSettings({ ...cloneDeep(userSettings), HIDDEN_LOAD_SEARCH_HEADERS: [] }))
          : setColumns(cloneDeep(useSourceFeasibilityFeature ? initialSourceColumnsWithFeasibilityChecker : initialSourceColumns))}
        sx={{ '&.MuiDrawer-root': { zIndex: 1400 } }}
      />
      {confirmAssignmentOpen && (
        <ConfirmSearchAssignment
          subject={LOAD}
          assignment={dynamicSourceBrokerageDescription([{ ...selectedLoad, has_source: true }], currentDriver, LOAD)}
          isOptimal={false}
          hasOptimal={false}
          isBrokerage={false}
          hasSource={true}
          isDriverPendingSourceRequest={false}
          onCancel={() => setConfirmAssignmentOpen(false)}
          onConfirm={() => {
            if (selectedLoad) {
              const { pta_to_pickup_miles, loaded_miles, pickup_location, dropoff_location, rate_per_miles, pickup_timezone, dropoff_timezone, pickup_start, dropoff_start, pickup_zip, dropoff_zip, last_location_to_pickup_miles, empty_to_next_stop, rate_per_total_miles } = selectedLoad;

              const sanitizeTimestamp = (timestamp: string) => timestamp.split('.')[0];

              const feasibility_checker = useSourceFeasibilityFeature ? {
                rate_per_total_miles,
                empty_to_next_stop,
                last_location_to_pickup_miles,
                hos_breakdown: null
              } : {};

              const source_search = {
                dropoff: dropoff_start ? sanitizeTimestamp(dropoff_start.replace('T', ' ').replace('Z', '')) : null,
                dropoff_city: dropoff_location.split(', ')[0],
                dropoff_city_state: dropoff_location,
                dropoff_state: dropoff_location.split(', ')[1],
                dropoff_tz: dropoff_timezone,
                dropff_zipcode: dropoff_zip,
                movement_type: CUSTOM_SOURCE_REQUEST,
                pickup: pickup_start ? sanitizeTimestamp(pickup_start.replace('T', ' ').replace('Z', '')) : null,
                pickup_city: pickup_location.split(', ')[0],
                pickup_city_state: pickup_location,
                pickup_state: pickup_location.split(', ')[1],
                pickup_tz: pickup_timezone,
                pickup_zipcode: pickup_zip,
                pta_to_pickup_miles,
                revenue: null,
                simulated_rate_per_mile: rate_per_miles,
                source_loaded_miles: loaded_miles,
                avail_for_dispatch_et: currentDriver.avail_for_dispatch_et,
                avail_timezone: currentDriver.avail_timezone,
                predispatch_final_loc: currentDriver.avail_city, 
                rate_per_total_miles,
                empty_to_next_stop,
                last_location_to_pickup_miles, 
                ...feasibility_checker,
              };
              instance.post(`/dispatching/drivers/${currentDriver?.driver.driver_id}/source-request/`, { source_search })
                .then(() => {
                  setSelectedLoad(null);
                  if (setShowSuccessSnackBar) setShowSuccessSnackBar(true);
                  setConfirmAssignmentOpen(false);
                  handleModalClose();
                })
                .catch((err) => console.error(err));
            }
          }}
        />
      )}
      <PermitsAndRequirementsDialog
        open={!!permitsDialogData}
        onClose={() => setPermitsDialogData(null)}
        permitRequired={permitsDialogData?.req_permits}
        permitDriver={currentDriver?.driver?.permits}
        equipment={permitsDialogData?.equipment}
        description={() => dynamicDescription(currentDriver?.driver, selectedLoad)}
      />
      <ODDialog fullScreen open={!!emailUrl} onClose={() => setEmailUrl(null)} title="Email Details">
        <EmailViewer emailUrl={emailUrl} />
      </ODDialog>
      {!!currOpenHOSBreakdown && (
        <HOSBreakdown
          currentDriver={currentDriver}
          sx={{ zIndex: 100000 }}
          open={useFeasibilityFastFollow}
          currOpenHOSBreakdown={currOpenHOSBreakdown}
          onClose={() => setCurrOpenHOSBreakdown(null)}
        />
      )}
    </Box>
  );
};

export default LoadsSearchExternal;
