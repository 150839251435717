/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode } from 'react';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Typography, Link } from '@mui/material';
import AlarmOnSharp from '@mui/icons-material/AlarmOnSharp';
import PlaceSharp from '@mui/icons-material/PlaceSharp';
import HomeSharp from '@mui/icons-material/HomeSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import PersonSharp from '@mui/icons-material/PersonSharp';
import PinDropSharp from '@mui/icons-material/PinDropSharp';
import { ODTooltip } from '@OptimalDynamics/core-ai-common-ui';
import {
  EstimatedTimeProps,
  MileageProps,
  EventRowProps,
  HomeRowProps,
  StopRowProps,
  DetailCardTimelineProps,
  LocationRowProps,
  AvailableTimeRowProps,
  HoldRowProps,
} from '../../types';
import { HOSIcon } from '../../../../common/icons';
import {
  getLocalizedTimeRange,
  DEFAULT_TIMEZONE,
  SHORT_DATE_TIME,
  SHORT_DATE_TIME_TZ,
  SHORT_DATE_TIME_AT_DIV,
  DATE_TIME_WITH_SEC_SPACE_DIV,
  DATE_TIME_WITH_SEC_T_DIV
} from '../../../../utils/datetimes';

const ESTIMATE_TYPE_TEXTS = {
  ETA: 'Estimated Time of Arrival',
  ETD: 'Estimated Time of Departure',
  ELT: 'Estimated Load Start & End Time',
  EULT: 'Estimated Unload Start & End Time',
  EPU: 'Est. Pick Up',
  EDO: 'Est. Drop Off',
};

export const generateTimestamp = (time = '', timezone = DEFAULT_TIMEZONE) => {
  if (!time) return '';
  const tzToUse = timezone ?? DEFAULT_TIMEZONE;

  const timeslot = moment.tz(time, [SHORT_DATE_TIME, DATE_TIME_WITH_SEC_SPACE_DIV], DEFAULT_TIMEZONE).tz(tzToUse);
  return timeslot.format(SHORT_DATE_TIME_TZ);
};

export const trimHos = (hos: string) => {
  if (hos?.startsWith('(')) {
    // HOS format is "(R) 18h 4m" or "(8D) 5h 23m" so I'm chopping off the parenthetical and its trailing space.
    // Checking that it is prefixed with ( jic because if the parenthetical isn't present no need to remove it.
    // @ts-expect-error this is not an error I check that it's not undefined in the if above
    return hos.split(')').at(-1).trimStart();
  }
};

export const SharedTimeLocationRow = ({ location, time, hideHour = false }: { location?: string, time?: string | null, hideHour?: boolean }) => {
  if (!!location && !!time) return <Typography>{`${location}${hideHour ? ' (3 Digit Zip) on ' : ':'} ${time}`}</Typography>;
  return (
    <>
      {location && <Typography>{location}</Typography>}
      {time && <Typography>{time}</Typography>}
    </>
  );
};

export const Mileage = ({ mileage = '0' }: MileageProps) => {
  const miles = parseFloat(mileage).toFixed(0);

  return (
    <Box sx={{ paddingLeft: '48px' }}>
      <Typography sx={{ color: 'text.secondary' }}>
        {mileage === 'TBD' ? mileage : `${miles} mi`}
      </Typography>
    </Box>
  );
};

export const EventRow = ({ icon, purpose, hos, openHosDialog, openExplainPtaDialog, children }: EventRowProps) => {
  const { odet4508AddModalToExplainPta } = useFlags();
  const showExplainPta = odet4508AddModalToExplainPta && openExplainPtaDialog;
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '32px auto', gridTemplateRows: 'auto', columnGap: '16px', '> div:not(:first-of-type)': { py: 0.5 } }}>
      <Box
        className={purpose ?? 'event-time'}
        sx={{
          gridColumnStart: 1,
          borderRadius: '4px',
          '&.scheduled': { backgroundColor: 'colors.accessibleLightBlue' },
          '&.prereq': { backgroundColor: 'warning.main' },
          '&.flexible': { backgroundColor: 'colors.lightBlue5', svg: { color: 'text.primary' } }
        }}
      >
        <Box sx={{ height: '32px', width: '32px', '& svg': { margin: '7px', color: 'text.inverse' } }}>
          {icon}
        </Box>
      </Box>
      <Box sx={{ gridColumnStart: 2 }}>
        {children}
      </Box>
      {(hos || showExplainPta) && (
        <Box
          sx={{
            gridColumnStart: 3,
            justifySelf: 'end',
            alignSelf: 'end',
            marginBottom: '1px',
            display: 'flex',
            alignItems: 'center',
            columnGap: '8px',
            '& svg': { color: 'text.secondary' }
          }}
        >
          {showExplainPta && (
            <Box className="more-info" sx={{ display: 'flex', columnGap: 1 }}>
              <AlarmOnSharp />
              <Link component="button" onClick={openExplainPtaDialog} sx={{ color: 'text.secondary', textDecoration: 'underline' }}>
                More Info
              </Link>
              {hos && <Box sx={{ borderRight: '1px solid', borderRightColor: 'level3', height: '18px', pr: 1, mr: 1 }} />}
            </Box>
          )}
          {hos && (
            <>
              <HOSIcon />
              <Link component="button" onClick={openHosDialog} sx={{ color: 'text.secondary', textDecoration: 'underline' }}>
                {hos}
              </Link>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export const EstimatedTime = ({ estimates }: EstimatedTimeProps) => {
  const types = Object.keys(estimates);
  let isSourceEstimate = false;

  // @ts-expect-error TS objects to using a string as an index key
  const unabbreviated = types.map((type) => ESTIMATE_TYPE_TEXTS[type]);
  let humanReadable = null as ReactNode;
  if (types.length === 1) {
    const type = types[0];
    if (['EPU', 'EDO'].includes(type)) isSourceEstimate = true;
    humanReadable = unabbreviated[0];
  } else {
    humanReadable = (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {types.map((type, i) => (<span>{`${type} = ${unabbreviated[i]}`}</span>))}
      </Box>
    );
  }

  return (
    <Box className="time-estimate" sx={{ marginTop: '8px', display: 'flex', columnGap: '8px', alignItems: 'center' }}>
      {!isSourceEstimate && (
        <ODTooltip title={humanReadable}>
          <InfoOutlined />
        </ODTooltip>
      )}
      <Box>
        <Typography>
          {Object.entries(estimates)?.map(([abbreviation, estimate], i) => {
            const [date, ...time] = estimate.split(' ');

            return (
              <>
                <strong>{`${isSourceEstimate ? humanReadable : abbreviation}: `}</strong>
                <span className="estimate-date">
                  {date}
                  &nbsp;
                </span>
                {time.join(' ')}
                {i !== (types.length - 1) && ` | `}
              </>
            );
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export const AvailableTimeRow = ({ available, timezone, location, hos, openHosDialog, openExplainPtaDialog, hasPrerequisite = false }: AvailableTimeRowProps) => {
  const tzToUse = timezone ?? DEFAULT_TIMEZONE;
  const availableTime = moment.tz(available, SHORT_DATE_TIME_AT_DIV, DEFAULT_TIMEZONE).tz(tzToUse);

  hos = !!hos ? trimHos(hos) : hos;

  return (
    <EventRow
      icon={<AlarmOnSharp sx={{ fill: hasPrerequisite ? 'black' : 'white' }} />}
      purpose={hasPrerequisite ? 'prereq' : 'available'}
      hos={hos}
      openHosDialog={openHosDialog}
      openExplainPtaDialog={openExplainPtaDialog}
    >
      <Typography><strong>Available For Dispatch</strong></Typography>
      <Typography>
        {`${availableTime.isSameOrBefore() ? 'Now, ' : ''}${location}`}
        <span className="avail-timestamp">{`: ${availableTime.format(SHORT_DATE_TIME_TZ)}`}</span>
      </Typography>
    </EventRow>
  );
};

export const LocationRow = ({
  label, start, end, estimates, timezone, location, mileage, hos, openHosDialog, icon = <PlaceSharp />, hideAppointment = false, hideHour = false
}: LocationRowProps) => {
  let timeWindow = '';
  if (hideHour) timeWindow = moment(start, 'YYYY-MM-DD HH:mm:ss').format('MM/DD');
  else if (!hideAppointment) timeWindow = getLocalizedTimeRange(start, end, timezone, [DATE_TIME_WITH_SEC_T_DIV, SHORT_DATE_TIME]);

  return (
    <>
      {mileage && <Mileage mileage={mileage} />}
      <EventRow icon={icon} hos={hos} openHosDialog={openHosDialog}>
        <Typography><strong>{label}</strong></Typography>
        <SharedTimeLocationRow time={timeWindow} location={location} hideHour={hideHour} />
        {(!hideAppointment && timeWindow === '') && <Typography sx={{ color: 'text.secondary' }}><em>No Appointment Window</em></Typography>}
        {(estimates) && <EstimatedTime estimates={estimates} />}
      </EventRow>
    </>
  );
};

export const StopRow = ({ estimate, timezone, location, mileage }: StopRowProps) => {
  const formattedEstimate = generateTimestamp(estimate, timezone);

  return (
    <>
      <Mileage mileage={mileage} />
      <EventRow icon={<PinDropSharp />}>
        <Typography><strong>Stop</strong></Typography>
        <Typography>{location}</Typography>
        {formattedEstimate && <EstimatedTime estimates={{ ETD: formattedEstimate }} />}
      </EventRow>
    </>
  );
};

export const HoldRow = ({ start, end, timezone, location }: HoldRowProps) => {
  const timeWindow = getLocalizedTimeRange(start, end, timezone, SHORT_DATE_TIME_AT_DIV);

  return (
    <EventRow icon={<PersonSharp />}>
      <Typography><strong>Driver Location</strong></Typography>
      <SharedTimeLocationRow time={timeWindow} location={location} />
    </EventRow>
  );
};

export const HomeRow = ({ label, time, timezone, location, mileage, timeWindow }: HomeRowProps) => {
  const formattedTime = generateTimestamp(time, timezone);

  return (
    <>
      {mileage && <Mileage mileage={mileage} />}
      <EventRow purpose="home-time" icon={<HomeSharp />}>
        <Typography><strong>{label}</strong></Typography>
        <SharedTimeLocationRow time={timeWindow} location={location} />
        {formattedTime && <EstimatedTime estimates={{ ETA: formattedTime }} />}
      </EventRow>
    </>
  );
};

export const DetailCardTimeline = ({ selected, children, isSource = false }: DetailCardTimelineProps) => (
  <Box sx={{ display: 'grid' }}>
    <Box
      sx={{
        gridColumn: 1,
        gridRow: 1,
        backgroundColor: selected ? 'colors.lightBlue2' : 'colors.neutral1',
        width: '32px',
        borderRadius: '4px'
      }}
    />
    <Box
      sx={{
        gridColumn: 1,
        gridRow: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
        ...(selected ? {
          '.event-time': { backgroundColor: isSource ? 'colors.neutral6' : 'colors.semanticBlue' },
          '.available': { backgroundColor: 'success.main' },
          '.home-time': { backgroundColor: 'colors.odBlue5' },
          '.time-estimate': { color: 'colors.semanticBlue' }
        } : {
          '.event-time, .available': { backgroundColor: 'colors.neutral4' },
          '.home-time': { backgroundColor: 'colors.neutral6' },
          '.time-estimate': { color: 'colors.neutral4' }
        })
      }}
    >
      {children}
    </Box>
  </Box>
);
