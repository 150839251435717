import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import { ODAvatar, ODNoDriverIcon, ODNoLoadIcon } from '@OptimalDynamics/core-ai-common-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NoAvailableMatchesAccordionProps } from '../../types';
import { DRIVER } from '../../helpers/constants';

export const NoAvailableMatchesAccordion = ({ type }: NoAvailableMatchesAccordionProps) => {
  const [expanded, setExpanded] = useState(true);
  const { showSourceSuggestions, useUnifiedExecuteLa } = useFlags();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', borderRadius: '4px', border: 1, borderColor: 'level2', }}>
      <Box
        sx={{ display: 'grid', grid: '48px / 18px auto 32px', alignItems: 'center', columnGap: '16px', padding: '0px 16px' }}
        onClick={() => setExpanded(!expanded)}
      >
        <Box sx={{ height: '32px', width: '32px', '> svg': { margin: '7px' } }}>{type === DRIVER ? <ODNoDriverIcon /> : <ODNoLoadIcon />}</Box>
        <Typography sx={{ ml: 2 }}>
          <strong>No Available Matches</strong>
        </Typography>
        <ODAvatar variant="transparent" size="small" onClick={() => setExpanded(!expanded)}>
          <ExpandMoreSharp sx={expanded ? { transform: 'rotate(0.5turn)' } : {}} />
        </ODAvatar>
      </Box>
      {expanded && (
        <Box sx={{ borderTop: 1, borderColor: 'level2', padding: '16px', whiteSpace: 'normal' }}>
          <Typography>
            {type === DRIVER ? (
              useUnifiedExecuteLa ? (
                <>
                  <b>There is no feasible load match available in your network at this time.</b>
                  {' '}
                  {`If there is an immediate need to plan the driver, consider Exception matches${
                    showSourceSuggestions ? ', manually searching for a load, or sending a source request.' : ' or manually searching for a load.'
                  }`}
                </>
              ) : (
                'There is no feasible load match available in your network. Wait for more loads to be booked, or manually book a load if there is an immediate need to have it planned.'
              )
            ) : (
              'There is no feasible driver match available in your network. Wait for more drivers to become available, or manually book a driver if there is an immediate need to have them planned.'
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
