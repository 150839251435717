import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import TagManager from 'react-gtm-module';
import * as serviceWorker from './serviceWorker';
import App from './App';

const tagManagerArgs = {
  gtmId: 'GTM-MW3TP9Z',
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
