import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Typography } from '@mui/material';
import HomeSharp from '@mui/icons-material/HomeSharp';
import HistorySharp from '@mui/icons-material/HistorySharp';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import { ODNoLoadIcon, ODTooltip } from '@OptimalDynamics/core-ai-common-ui/';
import moment from 'moment-timezone';
import { TuneOffIcon } from '../../../common/icons';
import {
  DRIVER_HOLD,
  EMPTY_TO_HOME,
  NO_AVAIL_MATCHES,
  READY,
} from '../helpers/constants';
import { getStarLabel } from '../helpers/dispatchingScripts';
import { DispatchingDriver, MatchSpecifics } from '../types';
import { AMERICAN_DATE_TIME, DATE_TIME_SPACE_DIV, DEFAULT_TIMEZONE } from '../../../utils/datetimes';
import { SOURCE_STATUS_SPECIFICS } from './SourceLoadDetailCard';

const DriverDispatchMatchCell = ({ row }: { row: DispatchingDriver }) => {
  const { showRelaxedMatches, orgUse2WayDispatching, showSourceSuggestions, hideMatchActionColumn, useSourceWorkflowEnhancements, useUnifiedExecuteLa } = useFlags();
  const {
    active,
    top_option_load_count,
    first_recommend_movement_type,
    is_relaxed,
    load_id,
    overall_rating,
    preferred_options_count,
    relaxed_options_count,
    movement_option_rank,
    has_source,
    has_source_request,
    source_search,
    sourcing_status,
    sourced_load_id
  } = row;

  let specifics = {} as MatchSpecifics;

  const isRelaxed = showRelaxedMatches && is_relaxed;
  const isSource = showSourceSuggestions && has_source;
  const isForecasted = useUnifiedExecuteLa && load_id?.startsWith('FORECASTED');
  let availableOptionsCount = preferred_options_count;
  availableOptionsCount += isRelaxed ? relaxed_options_count : 0;
 
  switch (true) {
    case has_source_request && showSourceSuggestions:
      if (useSourceWorkflowEnhancements) {
        // @ts-expect-error TS objects to using a string as an index key
        const status = SOURCE_STATUS_SPECIFICS[sourcing_status];
        if (sourcing_status === READY) {
          specifics = {
            text: sourced_load_id ?? status.label,
            icon: React.cloneElement(status.icon, { sx: { color: status.color } }),
            tooltip: status.label
          };
        } else {
          specifics = {
            text: status.label,
            icon: status.icon,
            tooltip: status.label
          };
        }
      } else {
        const pickup = source_search?.pickup || row.pickup_start;
        let duration = '-' as string | number;
        if (pickup) {
          // TODO API is returning two different types of format
          const parsedPickup = moment(pickup, [`${AMERICAN_DATE_TIME}:ss`, `${DATE_TIME_SPACE_DIV}:ss`], true);
          if (parsedPickup.isValid()) {
            duration = parsedPickup.tz(DEFAULT_TIMEZONE).diff(moment(), 'hours');
          }
        }
        specifics = {
          text: `${duration} HRS TO SOURCE`,
          icon: <ConnectWithoutContactSharp />,
          tooltip: `${duration} HRS TO SOURCE`
        };
      }
      break;
    case active === false:
      if (!!Number(top_option_load_count)) {
        specifics = {
          text: orgUse2WayDispatching ? 'ASSIGNED' : 'ADD TO TMS',
          icon: orgUse2WayDispatching ? <HistorySharp /> : <CheckCircleSharp sx={{ color: 'success.main' }} />,
          tooltip: orgUse2WayDispatching ? 'Assigned' : 'Add to TMS'
        };
      } else {
        specifics = {
          icon: <CheckCircleSharp sx={{ color: 'success.main' }} />,
          text: 'PLANNED',
          tooltip: 'Planned'
        };
      }
      break;
    // case for home time
    case first_recommend_movement_type === EMPTY_TO_HOME:
      specifics = {
        icon: isRelaxed ? <TuneOffIcon /> : <HomeSharp />,
        text: isSource ? 'SOURCE LOAD' : 'HOME TIME',
        tooltip: 'Home Time'
      };
      break;
    // cases for no matches at all
    case availableOptionsCount === 0:
    case availableOptionsCount === 1 && first_recommend_movement_type === DRIVER_HOLD:
    case !showRelaxedMatches && is_relaxed:
    case !showSourceSuggestions && has_source:
      specifics = {
        icon: <ODNoLoadIcon />,
        text: NO_AVAIL_MATCHES,
        tooltip: 'No Available Matches'
      };
      break;
    // case for load and tour recommendation
    case (Number(top_option_load_count) > 0):
      const loadStar = getStarLabel(overall_rating, movement_option_rank);
      const singleLoad = Number(top_option_load_count) === 1;
      const text = isSource ? `SOURCE ${singleLoad ? 'LOAD' : 'TOUR'}` : isForecasted ? `FORECASTED ${singleLoad ? 'LOAD' : 'TOUR'}` : (singleLoad ? load_id : `${Number(top_option_load_count)} LOADS`);
      specifics = {
        icon: isRelaxed ? <TuneOffIcon /> : loadStar.star,
        text,
        tooltip: isRelaxed ? 'Exception Match' : `${loadStar.label} Load Match`
      };
      break;
    default:
      return <></>;
  }

  if (Object.keys(specifics).length === 0) return <></>;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
      <ODTooltip title={specifics.tooltip} placement="right">
        {specifics.icon}
      </ODTooltip>
      <Typography sx={{ ...(!hideMatchActionColumn && { textDecoration: 'underline' }) }}>{specifics.text}</Typography>
    </Box>
  );
};

export default DriverDispatchMatchCell;
