import { LoadOption, SourceSearch } from '../types';
import { ASSET, LOADED } from './constants';

export const mapSourceSearchToLoadOption = (sourceSearch: SourceSearch): LoadOption => ({
  active: false,
  assigned: false,
  assigned_driver_id: '',
  assignment_rank: 0,
  deadhead_miles: '',
  dest_drop_flag: false,
  destination_city: sourceSearch?.dropoff_city,
  destination_state: sourceSearch?.dropoff_state,
  driver_id: '',
  driver_second_rank: 0,
  dropoff_end_time: '',
  dropoff_is_relay: false,
  dropoff_location: '',
  dropoff_start_time: '',
  dropoff_timezone: sourceSearch?.dropoff_tz,
  dropoff_est_arrival_time: '',
  dropoff_est_unloading_start_time: '',
  dropoff_est_unloading_end_time: '',
  engine_run_id: 0,
  equipment: '',
  est_dropoff_time: '',
  est_pickup_time: '',
  hard_dollar_cost: '0',
  hard_dollar_revenue: sourceSearch?.revenue,
  has_prerequisite: false,
  has_source: true,
  internal_source: ASSET,
  is_driver_tour: false,
  is_load_tour: false,
  is_relaxed: false,
  optimal_match: '',
  driver_first_name: '',
  driver_last_name: '',
  is_sleeper: false,
  load_id: '',
  load_second_rank: 0,
  loaded_miles: sourceSearch?.source_loaded_miles,
  movement_option_id: 0,
  movement_type: LOADED,
  no_of_stop: 0,
  orig_live_flag: false,
  overall_rating: 0,
  pickup_city: sourceSearch?.pickup_city,
  pickup_end_time: '',
  pickup_is_relay: false,
  pickup_location: '',
  pickup_start_time: '',
  pickup_state: sourceSearch?.pickup_state,
  pickup_timezone: sourceSearch?.pickup_tz,
  pickup_est_arrival_time: '',
  pickup_est_loading_start_time: '',
  pickup_est_loading_end_time: '',
  source_pickup: sourceSearch?.pickup,
  source_dropoff: sourceSearch?.dropoff,
  req_permits: '',
  shipper_id: '',
  submoves: [],
  tms_ready: false,
  shipper_name: '',
  avail_for_dispatch_et: sourceSearch.avail_for_dispatch_et,
  avail_timezone: sourceSearch.avail_timezone,
  predispatch_final_loc: sourceSearch.predispatch_final_loc,
  is_tentative: false,
  option: sourceSearch.option, 
  trailer_id: ''
});
