/* eslint-disable react/no-unstable-nested-components */

import React, { ReactElement, ReactNode } from 'react';
import { CSSObject, Box, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, useTheme } from '@mui/material';
import { ODSlideOut } from '@OptimalDynamics/core-ai-common-ui';
import FlagIcon from '@mui/icons-material/Flag';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AlarmOnOutlinedIcon from '@mui/icons-material/AlarmOnOutlined';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SlideoutLineItem from '../../../common/SlideoutLineItem';
import { HOSIcon } from '../../../common/icons';
import { AMERICAN_DATE_TIME, getLocalizedTime, getMinHosFigure, DATE_TIME_T_DIV } from '../../../utils/datetimes';
import { DispatchingDriver } from '../types';
import { ExternalLoad } from '../drivers/types';

export interface BaseColumnHeader {
  label: string;
  visible: boolean;
  selectionDisabled: boolean;
}

interface HOSBreakdownProps {
  open: boolean;
  onClose: () => void;
  sx?: CSSObject;
  currentDriver: DispatchingDriver;
  currOpenHOSBreakdown: ExternalLoad;
}

interface HOSDispatchAccordionProps {
  cardHeader: string;
  description: ReactElement;
  headerIcon: ReactNode;
  hos11: string;
  hos14: string;
  hos70: string;
  availableHours: string;
  subDescription: string;
}

function HOSBreakdown({ open, onClose, currentDriver, currOpenHOSBreakdown, sx = {} }: HOSBreakdownProps) {
  const theme = useTheme();

  const HOSDispatchAccordion = ({ cardHeader, description, hos11, hos14, hos70, headerIcon, availableHours, subDescription }: HOSDispatchAccordionProps) => (
    <Accordion
      defaultExpanded={true}
      sx={{
        borderRadius: '4px',
        boxShadow: 'none',
        border: `1px solid ${theme.palette.level3}`,
        marginBottom: '16px',
        '&:before': { display: 'none' }, 
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: 'black' }} />}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          {headerIcon}
          <Box>
            <Typography><strong>{cardHeader}</strong></Typography>
            <Typography>{description}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
  
      <AccordionDetails sx={{ padding: '0px 16px 16px 16px' }}>
        <Grid container>
          <Grid
            item
            xs={12}
            height="40px"
            sx={{
              backgroundColor: 'accent',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              p: '0 16px',
              marginBottom: '16px'
            }}
          >
            <HOSIcon sx={{ mr: '8px' }} />
            <strong>
              Available Hours:
              &nbsp;
            </strong>
            <Typography>{getMinHosFigure(availableHours)}</Typography>
          </Grid>
          <Box sx={{ width: '100%' }}>
            <SlideoutLineItem sx={{ justifyContent: 'initial', alignItems: 'center', display: 'flex' }} title="11 Hour Drive:" content={`${hos11} Remaining`} icon={<TimerOutlinedIcon />} />
            <SlideoutLineItem sx={{ justifyContent: 'initial' }} title="14 Hour Drive:" content={`${hos14} Remaining`} icon={<TimerOutlinedIcon />} />
            <SlideoutLineItem sx={{ justifyContent: 'initial' }} title="70 Hour Drive:" content={`${hos70} Remaining`} icon={<TimerOutlinedIcon />} />
          </Box>
          
          <Grid
            item
            xs={12}
            sx={{
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              p: '0 16px',
              marginTop: '16px', 
              color: 'colors.neutral6'
            }}
          >
            <Box
              sx={{
                height: 58,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                mr: '8px',
              }}
            >
              <InfoOutlinedIcon fontSize="small" />
            </Box>
            <Typography variant="body2">
              {subDescription}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
  
  return (
    <ODSlideOut
      open={open}
      title="HOS Breakdown"
      onClose={onClose}
      actionText="Reset"
      sx={sx}
    >
      <HOSDispatchAccordion
        cardHeader="Available for Dispatch"
        headerIcon={<AlarmOnOutlinedIcon />}
        description={(
          <>
            {currentDriver?.current_location ? `${currentDriver?.current_location}: ` : 'No Location Provided | '}
            {getLocalizedTime(currentDriver.avail_datetime, currentDriver?.avail_timezone, AMERICAN_DATE_TIME)}
          </>
      )}
        hos11={currentDriver?.road_mins_remain_end}
        hos14={currentDriver?.duty_mins_remain_end}
        hos70={currentDriver?.eight_days_mins_remain_end}
        availableHours={currentDriver?.hos_status}
        subDescription={`Clock values are forecasted out to when the driver is available for dispatch at ${getLocalizedTime(currentDriver?.avail_for_dispatch_et, currentDriver.avail_timezone)}.`}
      />
      <HOSDispatchAccordion
        cardHeader="Destination Dropoff"
        headerIcon={<FlagIcon />}
        description={(
          <>
            {currOpenHOSBreakdown?.dropoff_location ? `${currOpenHOSBreakdown?.dropoff_location}: ` : 'No Location Provided | '}
            {getLocalizedTime(currOpenHOSBreakdown?.dropoff_start, currOpenHOSBreakdown?.dropoff_timezone, DATE_TIME_T_DIV)}
          </>
        )}
        hos11={currOpenHOSBreakdown?.source_hos_rem_11}
        hos14={currOpenHOSBreakdown?.source_hos_rem_14}
        hos70={currOpenHOSBreakdown?.source_hos_rem_70}
        availableHours={currOpenHOSBreakdown?.source_hos_rem_11}
        subDescription={`Clock values are forecasted out to when the driver is forecasted to arrive at the location at ${getLocalizedTime(currOpenHOSBreakdown?.expected_delivery_start, currOpenHOSBreakdown?.dropoff_timezone, DATE_TIME_T_DIV)}`}
      />
    </ODSlideOut>
  );
}

export default HOSBreakdown;
