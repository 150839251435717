import moment, { Moment } from 'moment-timezone';
import { ENGINE_RUN_ERROR_THRESHOLD_MS } from '../common/EngineRunAlert';

export const DEFAULT_TIMEZONE = 'America/New_York';
export const UTC_TIMEZONE = 'UTC';

export const BASIC_DATE = 'YYYY-MM-DD';
export const AMERICAN_BASIC_DATE = 'MM-DD-YYYY';
export const SHORT_DATE = 'MM/DD';
export const BASIC_TIME = 'HH:mm';
export const BASIC_DATE_SLASH = BASIC_DATE.replace(/-/g, '/');
export const SHORT_FULL_DATE = `YY/${SHORT_DATE}`;
export const SHORT_FULL_DATE_US = `${SHORT_DATE}/YY`;
export const BASIC_TIME_TZ = `${BASIC_TIME} z`;
export const TIME_WITH_SEC = `${BASIC_TIME}:SS`;
export const DATE_TIME_SPACE_DIV = `${BASIC_DATE} ${BASIC_TIME}`;
export const DATE_TIME_SLASH_SPACE_DIV = `${BASIC_DATE_SLASH} ${BASIC_TIME}`;
export const DATE_TIME_WITH_SEC_SPACE_DIV = `${BASIC_DATE} ${TIME_WITH_SEC}`;
export const DATE_TIME_T_DIV = `${BASIC_DATE}T${BASIC_TIME}`;
export const DATE_TIME_T_DIV_TZ = `${BASIC_DATE}T${BASIC_TIME_TZ}`;
export const DATE_TIME_WITH_SEC_T_DIV = `${BASIC_DATE}T${TIME_WITH_SEC}`;
export const DATE_TIME_SLASH_WITH_SEC_T_DIV = `${BASIC_DATE_SLASH}T${TIME_WITH_SEC}`;
export const SHORT_DATE_TIME = `${SHORT_DATE} ${BASIC_TIME}`;
export const SHORT_DATE_TIME_TZ = `${SHORT_DATE_TIME} z`;
export const SHORT_DATE_TIME_AT_DIV = `${SHORT_DATE} @${BASIC_TIME}`;
export const SHORT_DATE_TIME_AT_DIV_TZ = `${SHORT_DATE} @${BASIC_TIME} z`;
export const AMERICAN_DATE_TIME = `${AMERICAN_BASIC_DATE} ${BASIC_TIME}`;
export const DATA_UPDATED_FORMAT = `${DATE_TIME_T_DIV} zZZ`;

/**
 * We decided that this method should return the Calendar Day differential rather than hour diff % 24.
 * Hence, we parse the original date handed in, then remove the HH:mm:ss etc, & use that in the diff
 */

export const getWindowDayDiff = (startMoment: Moment, endMoment: Moment): number => {
  if (!startMoment || !endMoment) return 0;

  const startDateTZ = startMoment.tz() ?? DEFAULT_TIMEZONE;
  const endDateTZ = endMoment.tz() ?? DEFAULT_TIMEZONE;
  const startDayMoment = startMoment.clone().tz(startDateTZ).startOf('day');
  const endDayMoment = endMoment.clone().tz(endDateTZ).startOf('day');

  return endDayMoment.diff(startDayMoment, 'days');
};

const buildTimeRange = (startMoment: Moment, endMoment: Moment) => {
  const dayDiff: number = getWindowDayDiff(startMoment, endMoment);
  const startFormatted: string = startMoment.format(SHORT_DATE_TIME);
  const endFormatted: string = endMoment.format(BASIC_TIME_TZ);
  return `${startFormatted}-${endFormatted}${dayDiff === 0 ? '' : ` (+${dayDiff})`}`;
};

export const getLocalizedIsoTimeRange = (
  startTime = '',
  endTime = '',
  timezone: string | null = DEFAULT_TIMEZONE
): string => {
  if (!startTime || !endTime) return '';
  const timezoneToUse: string = timezone ?? DEFAULT_TIMEZONE;

  const startMoment: Moment = moment.tz(startTime, timezoneToUse);
  const endMoment: Moment = moment.tz(endTime, timezoneToUse);

  return buildTimeRange(startMoment, endMoment);
};

export const getLocalizedIsoTime = (
  startTime = '',
  timezone: string | null = DEFAULT_TIMEZONE
): string => {
  if (!startTime) return '';
  const timezoneToUse: string = timezone ?? DEFAULT_TIMEZONE;
  const startMoment: Moment = moment(startTime).tz(timezoneToUse);
  return startMoment.format(SHORT_DATE_TIME_TZ);
};

export const getLocalizedTimeRange = (
  startTime: string | null | undefined = '',
  endTime: string | null | undefined = '',
  timezone: string | null = DEFAULT_TIMEZONE,
  inputFormat: string | null | string[] = DATE_TIME_T_DIV,
  isFlex = false,
  activity: string | null = null
): string => {
  if (!startTime || !endTime) return '';
  const timezoneToUse: string = timezone ?? DEFAULT_TIMEZONE;

  const startMoment: Moment = moment.tz(startTime, inputFormat ?? DATE_TIME_T_DIV, DEFAULT_TIMEZONE).tz(timezoneToUse);
  const endMoment: Moment = moment.tz(endTime, inputFormat ?? DATE_TIME_T_DIV, DEFAULT_TIMEZONE).tz(timezoneToUse);

  if (isFlex) {
    if (activity === 'pickup') {
      return startMoment.format(SHORT_DATE);
    }
    return `${startMoment.format(SHORT_DATE)}-${endMoment.format(SHORT_DATE)}`;
  }

  return buildTimeRange(startMoment, endMoment);
};

export const getLocalizedTime = (
  timeString: string | undefined | null,
  localTimeZone: string | undefined,
  inputFormat?: string | undefined,
  outputFormat?: string | undefined
): string => (
  moment.tz(timeString ?? '', inputFormat ?? SHORT_DATE_TIME, DEFAULT_TIMEZONE)
    .tz(localTimeZone ?? DEFAULT_TIMEZONE).format(outputFormat ?? SHORT_DATE_TIME_TZ)
);

export const getLocalizedDataUpdateDatetime = (input: string | null | undefined): string => getLocalizedTime(input, moment.tz.guess(), DATA_UPDATED_FORMAT);

// this function calculates the remaining time since dataUpdated to determine when 30 mins has passed
// for showing engine run alert banner, i.e. 30 min - (timeNow - dataUpdated)
export const getEngineRunAlertTimeDiff = (dataUpdated: string): number =>
  ENGINE_RUN_ERROR_THRESHOLD_MS - moment().diff(moment.tz(dataUpdated.split((/.EDT|.EST/g))[0], DATE_TIME_WITH_SEC_T_DIV, DEFAULT_TIMEZONE));

export const compareTimeToNow = (timeString: string | null, localTimeZone?: string | null, inputFormat?: string | null): number => {
  const timezoneToUse: string = localTimeZone ?? DEFAULT_TIMEZONE;
  const pta: Moment = moment.tz(timeString ?? '', inputFormat ?? SHORT_DATE_TIME, DEFAULT_TIMEZONE).tz(timezoneToUse);
  const time_now: Moment = moment().tz(timezoneToUse);
  return pta.diff(time_now);
};

export const getBrowserizedTime = (
  timeString: string | undefined,
  inputFormat: string | undefined,
  outputFormat: string | undefined
): string => {
  const tz: string = moment.tz.guess();
  return getLocalizedTime(timeString, tz, inputFormat, outputFormat);
};

export const getBrowserizedIsoTime = (
  timeString: string | undefined,
  outputFormat: string | undefined
): string => {
  const browserTz: string = moment.tz.guess();
  return moment.tz(timeString, DEFAULT_TIMEZONE).tz(browserTz).format(outputFormat);
};

// for dispatching
export const getSyncTimeDiff = (
  argDateTime: string | null,
  syncDateTime: string | null,
  timezone: string | null,
  argFormat?: string | null
): number | string => {
  if (!syncDateTime || !argDateTime) return Number.POSITIVE_INFINITY;
  const argMoment: Moment = moment.tz(argDateTime, argFormat ?? `${AMERICAN_DATE_TIME}:SS`, DEFAULT_TIMEZONE);
  // syncTime comes as YYYY-MM-DDTHH:mm E?T-0(4 or 5 offset from UTC depending on DST)00
  // which is unparse-able with the dual TZs. Chopping them off & using the Default TZ.
  const syncMoment: Moment = moment.tz(syncDateTime.split(' ')[0], DATE_TIME_T_DIV, DEFAULT_TIMEZONE);

  const timeDiff: number = argMoment.diff(syncMoment, 'minutes');
  return timeDiff <= 5 ? 'Now' : argMoment.tz(timezone ?? DEFAULT_TIMEZONE).format(`${SHORT_DATE_TIME_AT_DIV} z`);
};

export const getTimeZoneAcronym = (datetime: Moment, timezone: string): string => {
  if (!timezone) return '';
  const momentInTimezone = datetime.clone().tz(timezone);
  return momentInTimezone.format('z');
};

export const getMinHosFigure = (status: string) => status?.split(/(\(8D\) )|(\(D\) )|\(R\) /).pop();
